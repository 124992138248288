import { Box, Grid, TextField } from '@mui/material';
import CustomeSelect from '@/components/CustomeSelect';
import CustomeDate from '@/components/CustomeDate';
import { AllOptions, SearchDataType } from '@/types';
import { RootState, useSelector } from '@/redux';
import { useEffect, useState } from 'react';
import { getDistinctFieldValuesApi, queryApi } from '@/api/modules/metric';
import { getAll } from '@/api/modules/campaign';
import { FilterBy, Metric, CampaignItemConfig } from '@/api/interface';
import {
    campaignSearchKeyList,
    numberformatArgsObj,
    statusMap,
    campaignPageCol
} from '@/const';
import dayjs, { Dayjs } from 'dayjs';
import { DateRange } from '@mui/x-date-pickers-pro/models';
import UploadBtn from '@/components/Upload';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import {numberFormat, containsIgnoreCase} from '@/utils/util'
const dateDefaultValue = (): DateRange<Dayjs> => {
    const today = dayjs();
    return [today.subtract(7, 'day'), today];
};
function Index(): JSX.Element {
    const { advertiserItem } = useSelector((state: RootState) => state.account);
    const [searchData, setSsearchData] = useState<SearchDataType>();
    const [allOptions, setAllOptions] = useState<AllOptions>();
    const [columns, setColumns] = useState<GridColDef[]>([]);
    const [rows, setRows] = useState<any[]>([]);
    const [tabeleData, setTableData] = useState<any[]>([]);
    const [campaignConfig, setCampaignConfig] = useState<CampaignItemConfig[]>(
        []
    );
    const getAllOptions = async () => {
        const all = await Promise.all(
            campaignSearchKeyList.map(async (item) => {
                const params = {
                    field: item.key,
                    filter_by: [
                        {
                            field: 'advertiser_key',
                            args: [advertiserItem?.key || ''],
                        },
                    ],
                };
                const optionsitem = await getDistinctFieldValuesApi(params);
                return {
                    name: item.label,
                    data:
                        optionsitem.data?.map((v) => ({
                            label: v.split('_').join(' '),
                            key: v,
                        })) || [],
                };
            })
        );
        const obj = {} as AllOptions;
        all.forEach((item) => {
            if (!obj[item.name]) {
                obj[item.name] = item.data;
            }
        });
        setAllOptions(obj);
    };
    const getCol = () => {
        const col = campaignPageCol.map((v) => {
            return {
                headerName: v.label,
                field: v.key,
                minWidth: v.width || 100,
                flex: 1,
                valueGetter: (value: string) => {
                
                    if (!value) return '';
                    if(numberformatArgsObj[v.key]) {
                        const args = numberformatArgsObj[v.key]
                        args.num = Number(value)
                        return numberFormat(args)
                    }
                    if (v.key === 'status') {
                        return statusMap[value] || '';
                    }
                    return value;
                },
            } as GridColDef;
        });
        setColumns(col);
    };
    const getRowList = (
        data: any[],
        configList: CampaignItemConfig[]
    ): any[] => {
        const list = data.map((v, index) => {
            const target = configList.find(
                (ele) => ele.campaign === v.campaign
            );
            return {
                ...v,
                id: `${v.campaign}_${index}`,
                status: String(
                    target?.status === undefined ? '' : target.status
                ),
                budget: target?.budget || '',
            };
        });
        return list;
    };
    useEffect(() => {
        if (advertiserItem?.key) {
            getCol();
            getCampaignConfig();
            getAllOptions();
        }
    }, [advertiserItem?.key]);
    const getTableData = async (
        value: SearchDataType,
        configList: CampaignItemConfig[]
    ) => {
        const visible = columns
            .filter((v) => v.field !== 'campaign')
            .map((v) => v.field) as string[];
        const filterBy = [
            {
                field: 'advertiser_key',
                args: [advertiserItem?.key || ''],
            },
            {
                field: 'date',
                args: value.date,
            },
            {
                field: 'campaign',
                args: [value.campaign?.map((v) => v.key)],
            },
            {
                field: 'country',
                args: [value.country?.map((v) => v.key)],
            },
        ] as FilterBy[];

        const params = {
            visible,
            filter_by: filterBy,
            group_by: ['campaign'],
        } as Metric.ReqQuery;
        try {
            const res = await queryApi(params);
            if (res.data && res.data) {
                let filterData = getRowList(res.data, configList);
                setTableData(filterData);
                if (value.text) {
                    filterData = filterData.filter((v) =>
                        v.campaign.includes(value.text)
                    );
                }
                setRows(filterData);
            }
        } catch (error) {}
    };
    useEffect(() => {
        if (allOptions) {
            const [startTime, endTime] = dateDefaultValue();
            const value = {
                date: [
                    startTime?.format('YYYY-MM-DD'),
                    endTime?.format('YYYY-MM-DD'),
                ],
                country: allOptions?.country,
                campaign: allOptions?.campaign,
            } as SearchDataType;
            setSsearchData(value);
            getTableData(value, campaignConfig);
        }
    }, [allOptions, campaignConfig.length]);
    const getCampaignConfig = async () => {
        try {
            const res = await getAll({
                advertiser_key: advertiserItem?.key || '',
            });
            if (res.data) {
                setCampaignConfig(res.data);
            }
        } catch (error) {}
    };
    const onChange = (name: string, data?: any) => {
        const newSearchData = { ...(searchData || {}), [name]: data };
        if (name === 'text') {
            setRows(tabeleData.filter((item) =>containsIgnoreCase(item.campaign, data)));
        } else {
            const bool = Boolean(
                data?.length &&
                    newSearchData.campaign?.length &&
                    newSearchData.country?.length &&
                    newSearchData.date?.length
            );
            if (bool) {
                getTableData(newSearchData, campaignConfig);
            }
        }
        setSsearchData(newSearchData);
    };
    return (
        <Box component={'div'} sx={{ padding: '0 48px' }}>
            <Grid container spacing={2}>
                <Grid item xs='auto'>
                    <CustomeDate
                        name='date'
                        onChange={onChange}
                        defaultValue={dateDefaultValue()}
                    />
                </Grid>
                <Grid item xs={3.4}>
                    <CustomeSelect
                        name='campaign'
                        limitTags={1}
                        isHasAllSelectItem
                        label='Campaign'
                        optionList={allOptions?.campaign || []}
                        defaultValue={allOptions?.campaign || []}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={2.2}>
                    <CustomeSelect
                        name='country'
                        limitTags={1}
                        isHasAllSelectItem
                        label='Country'
                        optionList={allOptions?.country || []}
                        defaultValue={allOptions?.country || []}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={'auto'}>
                    <UploadBtn url='/campaign_config/upload' height='3.5rem'>
                        Upload campaign config
                    </UploadBtn>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        onChange={(e) => onChange('text', e.target.value)}
                        label='Search'
                        sx={{ mt: 2 }}
                    />
                </Grid>
            </Grid>
            <Box sx={{ width: '100%' }} pt={'84px'}>
                <DataGridPro
                    columns={columns}
                    rows={rows}
                    disableColumnFilter
                    disableColumnMenu
                    disableRowSelectionOnClick
                    isCellEditable={() => false}
                    autosizeOnMount={true}
                    disableColumnResize={true}
                    editMode={'row'}
                    autoHeight={true}
                    pageSizeOptions={[10, 20, 100]}
                    pagination
                    sx={{
                        border: 'none',
                        boxShadow: 'none',
                        '--DataGrid-overlayHeight': '300px',
                    }}
                    initialState={{
                        pagination: { paginationModel: { pageSize: 10 } },
                        pinnedColumns: {
                            left: ['campaign'],
                        },
                    }}
                />
            </Box>
        </Box>
    );
}
export default Index;
