import React from 'react';
import { BrowserRouter } from "react-router-dom";
import AuthRouter from "@/routers/utils/authRouter";
import Router from "@/routers/index";
function App() {
  return (
    <BrowserRouter>
        <AuthRouter>
					<Router />
				</AuthRouter>
		</BrowserRouter>
  );
}

export default App;
