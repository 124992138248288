import message from '@/components/Message';

/**
 * @description: 校验网络请求状态码
 * @param {Number} status
 * @return void
 */
export const checkStatus = (status: number): void => {
	switch (status) {
		case 403:
			message.error('Login failed! Please log in again')
			break;
		case 404:
			message.error('The resource you are accessing does not exist!')
			break;
		case 500:
			message.error('Service exception!')
			break;
		case 502:
			message.error('Gateway error!')
			break;
		case 503:
			message.error('service is not available!')
			break;
		case 504:
			message.error('Gateway timeout!')
			break;
		default:
			message.error('Request failed!')
	}
};
