import { GlobalState } from "@/redux/interface";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const globalState: GlobalState = {
	userInfo: null,
	assemblySize: "middle",
};

const globalSlice = createSlice({
	name: "global",
	initialState: globalState,
	reducers: {
		setUserInfo(state: GlobalState, { payload }: PayloadAction<any>) {
			state.userInfo = payload;
		}
	}
});

export const { setUserInfo } = globalSlice.actions;
export default globalSlice.reducer;
