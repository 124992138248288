import { Box, Grid, Typography, Stack } from "@mui/material";
import { AllOptions, SearchDataType, CardItem, ChartData } from "@/types";
import { campaignSearchKeyList, summaryCol, summaryColor } from "@/const";
import { RootState, useSelector } from "@/redux";
import { useEffect, useState } from "react";
import { getDistinctFieldValuesApi, queryApi } from "@/api/modules/metric";
import CustomeSelect from "@/components/CustomeSelect";
import CustomeDate from "@/components/CustomeDate";
import dayjs, { Dayjs } from "dayjs";
import { DateRange } from "@mui/x-date-pickers-pro/models";
import { FilterBy, Metric } from "@/api/interface";
import Card from "./card";
import Chart from "./chart";
import {numberFormat, NumberFormatArgs} from '@/utils/util'
const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format;
const dateDefaultValue = (): DateRange<Dayjs> => {
  const today = dayjs();
  return [today.subtract(7, "day"), today];
};

const getDateList = (dates: string[]): ChartData[] => {
  const [startTime, endTime] = dates;
  const startDate = dayjs(startTime);
  const endDate = dayjs(endTime);
  const datesArray = [];
  let currentDate = startDate;
  while (currentDate.isSameOrBefore(endDate, "day")) {
    datesArray.push({
      num: 0,
      date: currentDate.format("YYYY-MM-DD"),
    });
    currentDate = currentDate.add(1, "day");
  }
  return datesArray;
};
const getChartData = (
  dateList: ChartData[],
  chartObj: { [key: string]: ChartData }
): ChartData[] => {
    return dateList.map(v => {
        if(chartObj[v.date]) {
            const item = chartObj[v.date]
            const numStr = String(item.num);
            const num = numStr.includes('.')
            ? Number(Number.parseFloat(numStr).toFixed(4))
            : Number(numStr);
            v.num = num
            return v
        }
        return v
    })
};
const reorganizedData = (list: any[], dateList?: string[]): CardItem[] => {
  const data = summaryCol.map((v) => {
    let total = 0;
    let chartData = [] as ChartData[];
    const chartObj = {} as { [key: string]: ChartData };
    list.forEach((item) => {
      total += Number(`${item[v.key] ?? 0}`);
      if (chartObj[item.date.value]) {
        chartObj[item.date.value].num += Number(`${item[v.key] ?? 0}`);
      } else {
        chartObj[item.date.value] = {
          num: Number(`${item[v.key] ?? 0}`),
          date: item.date.value,
        };
      }
    });
    if (dateList) {
      chartData = getChartData(getDateList(dateList), chartObj)
    }
    const arg = {
      num: total,
      style: 'decimal',
    } as NumberFormatArgs;
    if(v.key === 'metric_cost') {
      arg.currency = 'USD'
      arg.style = 'currency'
    }
    if(v.key === 'metric_ctr') {
      arg.style = 'percent'
      arg.max = 2
    }
    return {
      ...v,
      ...summaryColor[v.key],
      total: numberFormat(arg),
      chartData,
    };
  });
  return data;
};

function Index(): JSX.Element {
  const [allOptions, setAllOptions] = useState<AllOptions>();
  const { advertiserItem } = useSelector((state: RootState) => state.account);
  const [searchData, setSsearchData] = useState<SearchDataType>();
  const [data, setData] = useState<CardItem[]>([]);
  const [currentData, setCurrentData] = useState<CardItem>();
  const getAllOptions = async () => {
    const all = await Promise.all(
      campaignSearchKeyList.map(async (item) => {
        const params = {
          field: item.key,
          filter_by: [
            {
              field: "advertiser_key",
              args: [advertiserItem?.key || ""],
            },
          ],
        };
        const optionsitem = await getDistinctFieldValuesApi(params);
        return {
          name: item.label,
          data:
            optionsitem.data?.map((v) => ({
              label: v.split("_").join(" "),
              key: v,
            })) || [],
        };
      })
    );
    const obj = {} as AllOptions;
    all.forEach((item) => {
      if (!obj[item.name]) {
        obj[item.name] = item.data;
      }
    });
    setAllOptions(obj);
  };
  useEffect(() => {
    if (advertiserItem?.key) {
      getAllOptions();
    }
  }, [advertiserItem?.key]);
  useEffect(() => {
    if (allOptions) {
      const [startTime, endTime] = dateDefaultValue();
      const value = {
        date: [startTime?.format("YYYY-MM-DD"), endTime?.format("YYYY-MM-DD")],
        country: allOptions?.country,
        campaign: allOptions?.campaign,
      } as SearchDataType;
      getTableData(value);
      setSsearchData(value);
    }
  }, [allOptions]);
  const getTableData = async (value: SearchDataType) => {
    const visible = summaryCol.map((v) => v.key) as string[];
    const filterBy = [
      {
        field: "advertiser_key",
        args: [advertiserItem?.key || ""],
      },
      {
        field: "date",
        args: value.date,
      },
      {
        field: "campaign",
        args: [value.campaign?.map((v) => v.key)],
      },
      {
        field: "country",
        args: [value.country?.map((v) => v.key)],
      },
    ] as FilterBy[];

    const params = {
      visible,
      filter_by: filterBy,
      group_by: ['date'],
    } as Metric.ReqQuery;
    try {
      const res = await queryApi(params);

      if (res.data) {
        const newData = reorganizedData(res.data, value.date);
        setData(newData);
        let newCurrentData = null;
        if (currentData) {
          newCurrentData = newData.find((item) => item.key === currentData.key);
        } else {
          newCurrentData = newData[0];
        }
        setCurrentData(newCurrentData);
      }
    } catch (error) {}
  };
  const onChange = async (name: string, data?: any) => {
    const newSearchData = { ...(searchData || {}), [name]: data };
    const bool = Boolean(
      data?.length &&
        newSearchData.campaign?.length &&
        newSearchData.country?.length &&
        newSearchData.date?.length
    );
    if (bool) {
      await getTableData(newSearchData);
    }
    setSsearchData(newSearchData);
  };
  return (
    <Box sx={{ p: "0 48px" }}>
      <Stack spacing={6}>
        <Typography variant="h5">Filters</Typography>
        <Grid container gap={2}>
          <Grid item xs={4}>
            <CustomeDate
              name="date"
              onChange={onChange}
              defaultValue={dateDefaultValue()}
            />
          </Grid>
          <Grid item xs={4}>
            <CustomeSelect
              name="campaign"
              limitTags={1}
              isHasAllSelectItem
              label="Campaign"
              optionList={allOptions?.campaign || []}
              defaultValue={allOptions?.campaign || []}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={3}>
            <CustomeSelect
              name="country"
              limitTags={1}
              isHasAllSelectItem
              label="Country"
              optionList={allOptions?.country || []}
              defaultValue={allOptions?.country || []}
              onChange={onChange}
            />
          </Grid>
        </Grid>
        <Typography variant="h5">Key Performance Metrics</Typography>
        <Grid container gap={2}>
          {data.map((v) => {
            return (
              <Grid item xs={1.71} key={v.key}>
                <Card
                  item={v}
                  currentData={currentData}
                  selectChange={(value) => setCurrentData(value)}
                />
              </Grid>
            );
          })}
        </Grid>
        {currentData && <Chart currentData={currentData} />}
      </Stack>
    </Box>
  );
}
export default Index;
