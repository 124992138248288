import { forwardRef, useState, useImperativeHandle, Ref } from "react";
import { FormControl, FormHelperText, FormLabel, Stack } from "@mui/material";
import { DateRangePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { Dayjs } from "dayjs";
import { DateRange } from "@mui/x-date-pickers-pro/models";
import { PickersShortcutsItem } from '@mui/x-date-pickers/PickersShortcuts';
import { CustomeDateProps, ErrorInfo } from "../../types";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
dayjs.extend(isSameOrBefore);
const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
  // {
  //   label: 'This Week',
  //   getValue: () => {
  //     const today = dayjs();
  //     return [today.startOf('week'), today.endOf('week')];
  //   },
  // },
  // {
  //   label: 'Last Week',
  //   getValue: () => {
  //     const today = dayjs();
  //     const prevWeek = today.subtract(7, 'day');
  //     return [prevWeek.startOf('week'), prevWeek.endOf('week')];
  //   },
  // },
  {
    label: 'Last 7 Days',
    getValue: () => {
      const today = dayjs();
      return [today.subtract(7, 'day'), today];
    },
  },
  {
    label: 'Last 14 Days',
    getValue: () => {
      const today = dayjs();
      return [today.subtract(14, 'day'), today];
    },
  },
  {
    label: 'Last 30 Days',
    getValue: () => {
      const today = dayjs();
      return [today.subtract(30, 'day'), today];
    },
  },
  // {
  //   label: 'Current Month',
  //   getValue: () => {
  //     const today = dayjs();
  //     return [today.startOf('month'), today.endOf('month')];
  //   },
  // },
  // {
  //   label: 'Last Month',
  //   getValue: () => {
  //     const today = dayjs();
  //     const startOfEndMonth = today.startOf('month').subtract(1, 'day');
  //     return [startOfEndMonth.startOf('month'), startOfEndMonth];
  //   },
  // },
];
const Index = (
  props: CustomeDateProps,
  ref: Ref<unknown> | undefined
): JSX.Element => {
  const { onChange, name, required, title, defaultValue=[null, null], textFieldSize = 'medium' } = props;
  
  const [date, setDate] = useState<DateRange<Dayjs>>(defaultValue);
  const [errorInfo, setError] = useState<ErrorInfo | null>();
  const validate = (startTime: string, endTime: string) => {
    if (required) {
      if (!(startTime && endTime)) {
        setError({ error: true, msg: "Please select a date" });
        return false;
      }
    }
    setError({ error: false, msg: "" });
    return true;
  };
  useImperativeHandle(ref, () => ({ validate }));
  const onDateChange = (newValue: DateRange<Dayjs>) => {
    const [startTime, endTime] = newValue;
    const startTimeStr = startTime?.format("YYYY-MM-DD") || "";
    const endTmeStr = endTime?.format("YYYY-MM-DD") || "";
    setDate(newValue);
    onChange(name, [startTimeStr, endTmeStr]);
    validate(startTimeStr, endTmeStr);
  };
  return (
    <FormControl
      component="fieldset"
      variant="standard"
      error={errorInfo?.error}
      sx={{width: '100%'}}
    >
      <Stack spacing={2}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateRangePicker
            localeText={{ start: "Start", end: "End" }}
            value={date}
            format="YYYY-MM-DD"
            onChange={onDateChange}
            
            slotProps={{
              shortcuts: {
                items: shortcutsItems
              },
              textField: {
                size: textFieldSize,
                required
              }
            }}
          />
        </LocalizationProvider>
      </Stack>
      <FormHelperText error={errorInfo?.error}>
        {errorInfo?.msg || ""}
      </FormHelperText>
    </FormControl>
  );
};

export default forwardRef(Index);
