import { useEffect, useState} from 'react'
import { Outlet } from "react-router-dom";
import LayoutMenu from './Menu'
import './index.scss'
import SiderHeader from './SiderHeader'
import Header from './Header'
import Divider from '@mui/material/Divider';
import { showMenuRouterMeat } from '@/routers'
import { useLocation } from "react-router-dom";
import { RouterMeat } from '@/routers/interface'
import { getAccountInfoApi } from '@/api/modules/account'
import { setUserInfo } from '@/redux/modules/global';
import { useDispatch } from '@/redux';

const LayoutIndex = (): JSX.Element => {
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const [routerItem, setrouterItem] = useState({} as RouterMeat)
    useEffect(() => {
        const routerItem = showMenuRouterMeat.find(item => item.path === pathname) || {} as RouterMeat;
        setrouterItem(routerItem)
    }, [pathname])
    const getUserInfo =  async () => {
        const userInfo = await getAccountInfoApi()
        dispatch(setUserInfo(userInfo.data));
    }
    useEffect(() => {
        getUserInfo();
    }, [])
    return (
        <section className="container">
            <div className='sider'>
                <div className='content'>
                    <SiderHeader></SiderHeader>
                    <LayoutMenu selectedRouterItem={routerItem}></LayoutMenu>
                </div>
            </div>
            <div className='layout'>
                <div className='header'>
                    <Header selectedRouterItem={routerItem}></Header>
                </div>
                <Divider sx={{ margin: '32px 0' }}></Divider>
                <div className='content'>
                    <Outlet></Outlet>
                </div>
            </div>
        </section>
    );
} 

export default LayoutIndex;