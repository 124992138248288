import { Metric } from "@/api/interface/index";
import { PORT2 } from "@/api/config/servicePort";
import http from "@/api";

/**
 * @name Metric模块
 */

export const queryApi = (params: Metric.ReqQuery) => {
    return http.post<any[]>(PORT2 + `/query`, params)
}
export const uploadApi = (params: FormData, url: string) => {
    return http.post<Metric.ReqUpload>(url, params,  { headers: { 'Content-Type':'multipart/form-data' } })
}
export const metricGetApi = (params: Metric.ReqMetricGet) => {
    return http.post<Metric.ResMetricGet>(PORT2 + `/get`, params,)
}
export const getDistinctFieldValuesApi = (params: Metric.ReqGetDistinctFieldValues) => {
    return http.post<string[]>(PORT2 + `/get_distinct_field_values`, params)
}