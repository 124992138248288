import { Account } from "@/api/interface/index";
import { PORT1 } from "@/api/config/servicePort";
import http from "@/api";

/**
 * @name 用户模块
 */
// * 用户登录接口
export const loginApi = (params: Account.ReqLoginForm) => {
	return http.post<Account.ResLogin>(PORT1 + `/login`, params);
};

export const getAccountInfoApi = (params?: Account.ReqGetAccountInfo) => {
	return http.post<Account.ResGetAccountInfo>(PORT1 + '/query', params)
}
export const logoutApi = () => {
	return http.post(PORT1 + '/logout' )
}
export const modifyPasswordApi = (params: Account.ReqModifyPassword) => {
	return http.post<Account.ResModifyPassword>(PORT1 + `/modify_password`, params);
};
export const getDistinctFieldValuesApi = (params: Account.ReqGetDistinctFieldValues) => {
    return http.post<any[]>(PORT1 + `/get_distinct_field_values`, params)
}
