import { createRoot } from "react-dom/client";
import "./styles/reset.scss";
import "./styles/common.scss";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { store, persistor } from "@/redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import App from "@/App";
import MuiXLicense from "@/components/MuiXLicense";
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
// import { BorderColor } from '@mui/icons-material';
const domNode = document.getElementById("root") as HTMLElement;
const root = createRoot(domNode);
const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#3865DC",
    },
    divider: "rgba(0, 0, 0, 0.12)",
    action: {
      selected: "rgba(56, 101, 220, 0.08)",
      hover: "rgba(56, 101, 220, 0.08)",
      // active: 'rgba(56, 101, 220, 0.08)',
      // focus: 'rgba(56, 101, 220, 0.08)',
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: 'rgba(0, 0, 0, 0.12)',
        },
        root: {
          [`.${outlinedInputClasses.input}`]: {
            fontFamily: 'Overpass',
          },
          [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: 'rgba(0, 0, 0, 0.12)',
          },
          [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: '#3865DC',
          },
        }
      }
    }
  },
  typography: {
    fontFamily: "Overpass",
    h5:{
      fontWeight: 600,
      fontSize: "1.125rem",
    },
    h6: {
      fontWeight: 600,
      fontSize: "1rem",
    },
  },
});
const RootNode = (): JSX.Element => (
  <ThemeProvider theme={defaultTheme}>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <App />
        <MuiXLicense />
      </PersistGate>
    </Provider>
  </ThemeProvider>
);
root.render(<RootNode />);
