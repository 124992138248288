import { RouteObject } from "@/routers/interface";
import Cookies from "js-cookie";
/**
 * @description 递归查询对应的路由
 * @param {String} path 当前访问地址
 * @param {Array} routes 路由列表
 * @returns array
 */
export const searchRoute = (
  path: string,
  routes: RouteObject[] = []
): RouteObject => {
  let result: RouteObject = {};
  for (let item of routes) {
    if (item.path === path) return item;
    if (item.children) {
      const res = searchRoute(path, item.children);
      if (Object.keys(res).length) result = res;
    }
  }
  return result;
};

export const getToken = (): string => {
  try {
    const token = Cookies.get("__quater_tech_token") || "";
    return token;
  } catch (error) {
    return "";
  }
};

export interface NumberFormatArgs {
  num: number;
  style?: "decimal" | "currency" | "percent";
  locales?: string;
  currency?: string;
  min?: number;
  max?: number;
}
export const numberFormat = (params: NumberFormatArgs): string => {
  const {
    num,
    style = "decimal",
    locales = "en-US",
    currency,
    max = 4,
    min = 0,
  } = params;
  return new Intl.NumberFormat(locales, {
    style,
    currency,
    minimumFractionDigits: min,
    maximumFractionDigits: max,
  }).format(num);
};


export function containsIgnoreCase(str: string, char: string): boolean {
  // 将字符串和目标字符都转换为小写
  const lowerCaseStr = str.toLowerCase();
  const lowerCaseChar = char.toLowerCase();
  
  // 使用 indexOf 函数检查字符是否存在
  return lowerCaseStr.includes(lowerCaseChar);
}