import react, { useEffect, useState } from 'react';
import { Snackbar, Alert } from '@mui/material';

import React from 'react';
import ReactDOM from 'react-dom/client';

interface MessageProps {
  severity: 'error' | 'info' | 'success' | 'warning';
  text: string;
  openTime: string
}
const Message: React.FC<MessageProps> = (props) => {

  const [open, setOpen] = useState(false);
  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    setOpen(false);
  };
  useEffect(() => {
    if(props.openTime) {
      setOpen(true)
    }
  }, [props.openTime])
  return (
    <>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity={props.severity} variant='filled'>{props.text}</Alert>
      </Snackbar>
    </>
  );
}

class message {
  dom: HTMLElement
  reactDOM
  constructor() {
    this.dom =  document.createElement('div');
    this.reactDOM =  ReactDOM.createRoot(this.dom)
  }
  success(text: string){
    const JSXdom = (<Message severity={'success'} text={text} openTime={String(Date.now())} ></Message>);
    this.reactDOM.render(JSXdom)
    document.body.appendChild(this.dom);
  }
  error(text: string){
    const JSXdom = (<Message severity={'error'} text={text} openTime={String(Date.now())}></Message>);
    this.reactDOM.render(JSXdom)
    document.body.appendChild(this.dom);
  }
  info(text: string){
    const JSXdom = (<Message severity={'info'} text={text} openTime={String(Date.now())} ></Message>);
    this.reactDOM.render(JSXdom)
    document.body.appendChild(this.dom);
  }
  warning(text: string){
    const JSXdom = (<Message severity={'warning'} text={text} openTime={String(Date.now())}></Message>);
    this.reactDOM.render(JSXdom)
    document.body.appendChild(this.dom);
  }
}
export default new message();