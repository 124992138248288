import { useEffect, useMemo, useState } from "react";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import { showMenuRouterMeat } from "@/routers/index";
import { RouterMeat } from "@/routers/interface";
import { useNavigate } from "react-router-dom";
import { Box, Autocomplete, TextField } from "@mui/material";
import { useSelector, RootState, useDispatch } from "@/redux";
import { setAdvertiserList, setAdvertiserItem } from "@/redux/modules/account";
import "./index.scss";
import { getDistinctFieldValuesApi } from "@/api/modules/account";
type LayoutMenuProps = {
  selectedRouterItem: RouterMeat;
};
interface OptionsItem {
  label: string;
  key: string;
}
interface AdvertiserFormType {
  advertiser?: OptionsItem;
}
const LayoutMenu = (props: LayoutMenuProps): JSX.Element => {
  const { selectedRouterItem } = props;
  const { userInfo, advertiserItem } = useSelector((state: RootState) => ({
    userInfo: state.global.userInfo,
    advertiserItem: state.account.advertiserItem,
  }));
  const [advertiserForm, setAdvertiserForm] = useState<AdvertiserFormType>({
    advertiser: advertiserItem || undefined,
  });

  const [advertiserOptionList, setAdvertiseroptionList] = useState<
    OptionsItem[]
  >([]);
  const disptch = useDispatch();
  const navigate = useNavigate();
  const menuClick = (value: RouterMeat) => {
    navigate(value.path);
  };
  //   const
  const handleChange = (value: OptionsItem | null) => {
    if (value) {
      if (value.key === advertiserForm.advertiser?.key) return;
      disptch(setAdvertiserItem(value));
      window.location.reload();
    }
  };
  const getadvertiserList = async () => {
    try {
      const res = (await getDistinctFieldValuesApi({
        field: `advertiser_key`,
      })) as any;

      const newAdvertiserList = res.data?.map((v: any) => ({
        label: v,
        key: v,
      }));
      if (newAdvertiserList.length) {
        if (!advertiserItem) {
          disptch(setAdvertiserItem(newAdvertiserList[0]));
          setAdvertiserForm({ advertiser: newAdvertiserList[0] });
        }
        setAdvertiseroptionList(newAdvertiserList);
        disptch(setAdvertiserList(newAdvertiserList));
      }
    } catch (error) {}
  };
  const isAdmin = useMemo(() => {
    return ["admin", "advertiser_admin"].includes(userInfo?.role)
  }, [userInfo?.role]);
  useEffect(() => {
    if(userInfo){
      if (isAdmin) {
        getadvertiserList();
      } else {
        setAdvertiseroptionList([
          { label: userInfo.advertiser_key, key: userInfo.advertiser_key },
        ]);
        setAdvertiserForm({
          advertiser: {
            label: userInfo.advertiser_key,
            key: userInfo.advertiser_key,
          },
        });
        disptch(
          setAdvertiserItem({
            label: userInfo.advertiser_key,
            key: userInfo.advertiser_key,
          })
        );
        disptch(
          setAdvertiserList([
            { label: userInfo.advertiser_key, key: userInfo.advertiser_key },
          ])
        );
      }
    }
    
  }, [userInfo?.role]);
  return (
    <Box component={"div"} sx={{ margin: 2.5 }}>
      {isAdmin ? (
        <Autocomplete
          disableClearable
          options={advertiserOptionList}
          renderInput={(params) => <TextField {...params} hiddenLabel />}
          value={advertiserForm?.advertiser}
          onChange={(e, newValue) => handleChange(newValue)}
          isOptionEqualToValue={(option, value) => option?.key === value?.key}
          size="small"
          sx={{ width: 215, bgcolor: "#fff", mb: 1 }}
        />
      ) : null}
      <MenuList>
        {showMenuRouterMeat?.map((item) => {
          return (
            <MenuItem
              divider
              className="MenuItem"
              key={item.path}
              onClick={() => menuClick(item)}
              selected={item.name === selectedRouterItem.name}
            >
              {item.name}
            </MenuItem>
          );
        })}
      </MenuList>
      <Box
        component={"div"}
        sx={{ position: "absolute", bottom: 24, width: 215, bgcolor: "#fff" }}
      >
        <MenuItem
          sx={{ "&:hover": { bgcolor: "#fff" } }}
          onClick={() => navigate("/user")}
        >
          {userInfo?.user_name}
        </MenuItem>
      </Box>
    </Box>
  );
};

export default LayoutMenu;
