
import loginHeader from '@/assets/svg/loginHeader.svg'
import { Box } from '@mui/material';

const SiderHeader = (): JSX.Element => {
    return (
        <Box component={'div'} sx={{ margin: 2.3 }}>
            <img src={loginHeader} alt="" />
        </Box>
    )
}
export default SiderHeader