import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Button,
    TextField,
    FormControlLabel,
    Checkbox,
    FormControl,
    Box,
    Typography,
    Card,
    Divider,
    Stack,
} from '@mui/material';
import loginHeader from '@/assets/svg/loginHeader.svg';
import { SUMMARY_URL } from '@/config';
import styles from './index.module.scss';
import { loginApi } from '@/api/modules/account';

type FormData = {
    user_name?: string, 
    password?: string, 
    checked?: boolean,
    disabled?: boolean
} | null
export default function Login() {
    const navigate = useNavigate();
    const [formData, setFomrData] = useState<FormData>(null)
    const validate = (data: FormData): boolean => {
        return Boolean(data?.user_name && data?.password && data?.checked);
    };
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const isValidate = validate(formData);
        if (isValidate) {
            const res = await loginApi({user_name: formData?.user_name || '', password: formData?.password || ''});
            if(res.code === 0) {
                navigate(SUMMARY_URL);
            }
        }
        return;
    };
    const onChange = (value: any, key: string) => {
        const newFormData = {...(formData || {}), [key]: value,}
        setFomrData({...newFormData, disabled: validate(newFormData)})
    }
    return (
        <div className={styles.root}>
            <Card sx={{ width: 500, p: 3 }}>
                <Stack spacing={2}>
                    <Stack spacing={5}>
                        <Box sx={{ width: 218.45, height: 31.7 }}>
                            <img src={loginHeader} alt='' />
                        </Box>
                        <Box component={'div'}>
                            <Typography component='h5' sx={{ fontSize: 24 }}>
                                Sign in
                            </Typography>
                            <Divider sx={{ mt: 2, mb: 2 }} />
                        </Box>
                    </Stack>

                    <Box component='form' onSubmit={handleSubmit} noValidate>
                        <Stack spacing={2}>
                            <TextField
                                margin='normal'
                                required
                                fullWidth
                                id='email'
                                label='Email Address'
                                name='email'
                                autoComplete='email'
                                autoFocus
                                onChange={(e) => onChange(e.target.value, 'user_name')}
                            />
                            <TextField

                                margin='normal'
                                required
                                fullWidth
                                name='password'
                                label='Password'
                                type='password'
                                id='password'
                                autoComplete='current-password'
                                onChange={(e) => onChange(e.target.value, 'password')}
                            />
                            <Typography variant='body2'>
                                Forgot password?
                            </Typography>

                            <FormControl >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={(e) => onChange(e.target.checked, 'checked')}
                                            checked={formData?.checked}
                                            value='remember'
                                            color='primary'
                                        />
                                    }
                                    label='I accept the Terms and Conditions'
                                />
                            </FormControl>
                            <Button
                                type='submit'
                                fullWidth
                                disabled={!Boolean(formData?.disabled)}
                                variant='contained'
                                sx={{ textTransform: 'none' }}
                                size='large'
                            >
                                Sign In
                            </Button>
                        </Stack>
                    </Box>
                </Stack>
            </Card>
        </div>
    );
}
