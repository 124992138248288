import {
  Box,
  Typography,
  Divider,
  Stack,
  TextField,
  Button,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { modifyPasswordApi, logoutApi } from "@/api/modules/account";
import { RootState, useSelector } from "@/redux";
import message from "@/components/Message";

interface FormItemData {
  value: string;
  error: boolean;
  required?: boolean;
  errorMsg?: string;
  label?: string;
}
type Formkey = "currentPassword" | "newPassword" | "confirmPassword";
type FormDataType = {
  [key in Formkey]: FormItemData;
};

const defaultVlaue = {
  currentPassword: {
    value: "",
    required: true,
    error: false,
    label: "Current password",
  },
  newPassword: {
    value: "",
    required: true,
    error: false,
    label: "New password",
  },
  confirmPassword: {
    value: "",
    required: true,
    label: "Confirm password",
    error: false,
  },
} as FormDataType;
function Index(): JSX.Element {
  const { userInfo } = useSelector((state: RootState) => state.global);

  const [formData, setFormData] = useState<FormDataType>();

  const navigate = useNavigate();
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFormData = (formData || {}) as FormDataType;
    const name = e.target.name as Formkey;
    const text = e.target.value || "";
    const formitem = {
      [name]: {
        value: text,
        required: true,
        error: false,
        label: defaultVlaue[name].label,
      },
    } as FormDataType;
    const { data } = validate(formitem);
    setFormData({ ...newFormData, ...data });
  };
  const validate = (formValue?: FormDataType) => {
    const newFormData = { ...(formValue || defaultVlaue) } as FormDataType;
    let status = true;
    for (const key in newFormData) {
      const {
        value,
        required = false,
        label = "",
      } = newFormData[key as Formkey];
      if (required) {
        if (value) {
          newFormData[key as Formkey].error = false;
          newFormData[key as Formkey].errorMsg = undefined;
        } else {
          newFormData[key as Formkey].error = true;
          newFormData[key as Formkey].errorMsg = `${label} is required`;
          status = false;
        }
      }
    }
    return { status, data: { ...newFormData } };
  };
  const onSubmit = async () => {
    const { status, data } = validate(formData);
    if (status) {
      if (data.newPassword.value !== data.confirmPassword.value) {
        message.error(
          "The confirmation password is inconsistent with the new password, please enter it again"
        );
        return;
      }
      try {
        const params = {
          user_name: userInfo?.user_name,
          old_password: data.currentPassword.value,
          new_password: data.newPassword.value,
        };
        const res = await modifyPasswordApi(params);
        if (res.code === 0) {
          message.success("Password changed successfully");
        } else {
          message.error("Failed to change password");
        }
      } catch (error) {
        message.error("Failed to change password");
        console.log(error);
      }
    } else {
      setFormData(data);
      return;
    }
  };
  const onSignOut = async () => {
    try {
      await logoutApi()
      navigate("/signIn");
    } catch (error) {
      console.error(error)
    }

  };
  return (
    <Box component={"div"}>
      <Box component={"div"} sx={{ p: "0 48px " }}>
        <Stack component={"div"} spacing={6}>
          <Typography variant="h5">Edit password</Typography>
          <Stack component={"div"} spacing={2} maxWidth={500}>
            <TextField
              variant="outlined"
              size="small"
              name="currentPassword"
              type="password"
              label="Current password"
              onChange={onChange}
              value={formData?.currentPassword?.value || ""}
              error={formData?.currentPassword?.error}
              helperText={formData?.currentPassword?.errorMsg}
            />
            <TextField
              variant="outlined"
              size="small"
              name="newPassword"
              type="password"
              label="New password"
              onChange={onChange}
              value={formData?.newPassword?.value || ""}
              error={formData?.newPassword?.error}
              helperText={formData?.newPassword?.errorMsg}
            />
            <TextField
              variant="outlined"
              size="small"
              name="confirmPassword"
              type="password"
              label="Confirm password"
              onChange={onChange}
              value={formData?.confirmPassword?.value || ""}
              error={formData?.confirmPassword?.error}
              helperText={formData?.confirmPassword?.errorMsg}
            />
          </Stack>
          <Button
            type="submit"
            variant="contained"
            sx={{ textTransform: "none", height: "43px", width: "93px" }}
            size="medium"
            onClick={() => onSubmit()}
          >
            Confirm
          </Button>
        </Stack>
      </Box>
      <Divider sx={{ margin: "32px 0" }} />
      <Box component={"div"} sx={{ p: "0 48px" }}>
        <Button
          type="submit"
          variant="outlined"
          sx={{ textTransform: "none", height: "43px", width: "93px" }}
          size="medium"
          onClick={() => onSignOut()}
        >
          Sign out
        </Button>
      </Box>
    </Box>
  );
}

export default Index;
