import { RouterMeat } from '@/routers/interface';
import { Box } from '@mui/material';
import { RootState, useSelector } from '@/redux';
import { useLocation } from 'react-router-dom';
import UploadBtn from '@/components/Upload';
import { useEffect, useState } from 'react';
type HeaderProps = {
    selectedRouterItem: RouterMeat;
};

const Header = (props: HeaderProps): JSX.Element => {
    const { pathname } = useLocation();
    const { userInfo } = useSelector((state: RootState) => state.global);
    const { selectedRouterItem } = props;
    const [title, setTittle] = useState('');
    useEffect(() => {
        if (pathname === '/user') {
            setTittle(userInfo?.user_name || '');
            return;
        }
        setTittle(selectedRouterItem.name || '');
    }, [userInfo?.name, pathname, selectedRouterItem.name]);
    return (
        <Box
            sx={{
                display: 'flex',
                p: '24px 48px 0 48px',
                alignItems: 'center',
            }}
        >
            <Box
                sx={{
                    flex: 1,
                    fontSize: 24,
                    fontWeight: 600,
                    alignItems: 'center',
                }}
            >
                {title}
            </Box>
            {pathname === '/report' && (
                <UploadBtn url='/metric/upload' styles={{ mt: 0 }}>
                    Upload metric
                </UploadBtn>
            )}
        </Box>
    );
};
export default Header;
