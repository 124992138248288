import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccountState, AdvertiserItem } from '../interface'
const accountState = {
    advertiserList: [] as AdvertiserItem[],
    advertiserItem: null as AdvertiserItem | null
}

const accountSlice = createSlice({
	name: "account",
	initialState: accountState,
	reducers: {
        setAdvertiserList(state: AccountState, { payload }: PayloadAction<AdvertiserItem[]>) {
            state.advertiserList = payload
        },
        setAdvertiserItem(state: AccountState, { payload }: PayloadAction<AdvertiserItem>){
            state.advertiserItem = payload
        }
	}
});

export default accountSlice.reducer
export const { setAdvertiserList, setAdvertiserItem } = accountSlice.actions