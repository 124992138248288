import {CardItem} from '@/types'
import {NumberFormatArgs} from '@/utils/util'
export const dimensionList = [
  {
    label: "Date",
    key: "date",
  },
  {
    label: "Channel",
    key: "channel_key",
  },
  {
    label: "Campaign",
    key: "campaign",
  },
  {
    label: "Ad group",
    key: "ad_group",
  },
  {
    label: "Country",
    key: "country",
  },
];

export const metricsList = [
  {
    label: "Cost",
    key: "metric_cost",
    margin: "metric_parsed_cost",
    marginName: "Parsed Cost",
  },
  {
    label: "Impressions",
    key: "metric_impression",
  },
  {
    label: "Clicks",
    key: "metric_click",
  },
  {
    label: "View (video)",
    key: "metric_video_view",
  },
  {
    label: "Install",
    key: "metric_install",
  },
  {
    label: "View completion",
    key: "metric_view_completion",
  },
  {
    label: "CTR (%)",
    key: "metric_ctr",
  },
  {
    label: "CVR (%)",
    key: "metric_cvr",
  },
  {
    label: "CPC",
    key: "metric_cpc",
    margin: "metric_parsed_cpc",
    marginName: "Parsed CPC",
  },
  {
    label: "CPM",
    key: "metric_cpm",
    margin: "metric_parsed_cpm",
    marginName: "Parsed CPM",
  },
  {
    label: "Conversion 1",
    key: "metric_conversion_1",
  },
  {
    label: "Cost Per Conversion 1",
    key: "metric_cost_per_conversion_1",
    marginName: "Parsed Cost Per Conversion 1",
    margin: "metric_parsed_cost_per_conversion_1",
  },
  {
    label: "Conversion 2",
    key: "metric_conversion_2",
  },
  {
    label: "Cost Per Conversion 2",
    key: "metric_cost_per_conversion_2",
    marginName: "Parsed Cost Per Conversion 2",
    margin: "metric_parsed_cost_per_conversion_2",
  },
  {
    label: "Conversion 3",
    key: "metric_conversion_3",
  },
  {
    label: "Cost Per Conversion 3",
    key: "metric_cost_per_conversion_3",
    marginName: "Parsed Cost Per Conversion 3",
    margin: "metric_parsed_cost_per_conversion_3",
  },
  {
    label: "Conversion 4",
    key: "metric_conversion_4",
  },
  {
    label: "Cost Per Conversion 4",
    key: "metric_cost_per_conversion_4",
    marginName: "Parsed Cost Per Conversion 4",
    margin: "metric_parsed_cost_per_conversion_4",
  },
  {
    label: "Conversion 5",
    key: "metric_conversion_5",
  },
  {
    label: "Cost Per Conversion 5",
    key: "metric_cost_per_conversion_5",
    marginName: "Parsed Cost Per Conversion 5",
    margin: "metric_parsed_cost_per_conversion_5",
  },
  {
    label: "Conversion 6",
    key: "metric_conversion_6",
  },
  {
    label: "Cost Per Conversion 6",
    key: "metric_cost_per_conversion_6",
    marginName: "Parsed Cost Per Conversion 6",
    margin: "metric_parsed_cost_per_conversion_6",
  },
  {
    label: "Conversion 7",
    key: "metric_conversion_7",
  },
  {
    label: "Cost Per Conversion 7",
    key: "metric_cost_per_conversion_7",
    marginName: "Parsed Cost Per Conversion 7",
    margin: "metric_parsed_cost_per_conversion_7",
  },
  {
    label: "Conversion 8",
    key: "metric_conversion_8",
  },
  {
    label: "Cost Per Conversion 8",
    key: "metric_cost_per_conversion_8",
    marginName: "Parsed Cost Per Conversion 8",
    margin: "metric_parsed_cost_per_conversion_8",
  },
  {
    label: "Conversion 9",
    key: "metric_conversion_9",
  },
  {
    label: "Cost Per Conversion 9",
    key: "metric_cost_per_conversion_9",
    marginName: "Parsed Cost Per Conversion 9",
    margin: "metric_parsed_cost_per_conversion_9",
  },
  {
    label: "Conversion 10",
    key: "metric_conversion_10",
  },
  {
    label: "Cost Per Conversion 10",
    key: "metric_cost_per_conversion_10",
    marginName: "Parsed Cost Per Conversion 10",
    margin: "metric_parsed_cost_per_conversion_10",
  },
];

export const filterOptions = [
  {
    label: "Campaign",
    key: "campaign",
  },
  {
    label: "Country",
    key: "country",
  },
  {
    label: "Ad group",
    key: "ad_group",
  },
];

export const filterOptionsObj = {
  campaign: "Campaign",
  country: "country",
  ad_group: "Ad group",
} as { [key: string]: string };
export const reportSearchKeyList = [
  {
    label: "channel",
    key: "channel_key",
  },
  {
    label: "ad_group",
    key: "ad_group",
  },
  {
    label: "campaign",
    key: "campaign",
  },
  {
    label: "country",
    key: "country",
  },
];

export const campaignSearchKeyList = [
  {
    label: "campaign",
    key: "campaign",
  },
  {
    label: "country",
    key: "country",
  },
];

export const campaignPageCol = [
  {
    key: "campaign",
    label: "Campaign Name",
    width: 230
  },
  {
    key: "budget",
    label: "Budget",
  },
  {
    key: 'status',
    label: 'Status'
  },
  {
    key: "metric_cost",
    label: "Spend",
  },
  {
    key: "metric_impression",
    label: "Impression",
  },
  {
    key: "metric_click",
    label: "Click",
  },
  {
    key: "metric_view_completion",
    label: "View",
  },
  {
    key: "metric_install",
    label: "Install",
  },
  {
    key: "metric_conversion_1",
    label: "Conv Event 1",
  },
  {
    key: "metric_conversion_2",
    label: "Conv Event 2",
  },
  {
    key: "metric_conversion_3",
    label: "Conv Event 3",
  },
  {
    key: "metric_ctr",
    label: "CTR",
  },
  {
    key: "metric_cvr",
    label: "CVR",
  },
  {
    key: "metric_cpm",
    label: "CPM",
  },
  {
    key: "metric_cpc",
    label: "CPC",
  },
  {
    key: "metric_cpi",
    label: "CPI",
  },
  // {
  //   key: "cpa1",
  //   label: "CPA 1",
  // },
  // {
  //   key: "cpa2",
  //   label: "CPA 2",
  // },
  // {
  //   key: "cpa3",
  //   label: "CPA 3",
  // },
];

export const statusMap = {
  0: 'off',
  1: 'on'
} as {[key: string]: string}

export const campaignPageMetricFiledList = [
  "metric_cost",
  "metric_impression",
  "metric_click",
  "metric_view_completion",
  "metric_install",
  "metric_conversion_1",
  "metric_conversion_2",
  "metric_conversion_3",
  "metric_ctr",
  "metric_cvr",
  "metric_cpm",
  "metric_cpc",
  "metric_cpi"
]

export const summaryCol = [
  {
    key: "metric_cost",
    label: "Spend",
  },
  {
    key: "metric_click",
    label: "Clicks",
  },
  {
    key: "metric_impression",
    label: "Impressions",
  },
  {
    key: "metric_install",
    label: "Install",
  },
  {
    key: "metric_conversion_1",
    label: "Conv Event 1",
  },
  {
    key: "metric_ctr",
    label: "CTR",
  },
];


export const summaryColor = {
  metric_cost: {
    color: '#2196f3',
    areaStartColor: '#2196f333',
    areaEndColor: '#2196f300'
  },
  metric_click: {
    color: '#9C27B0',
    areaStartColor: '#9C27B033',
    areaEndColor: '#9C27B000'
  },
  metric_impression: {
    color: '#2E7D32',
    areaStartColor: '#2E7D3233',
    areaEndColor: '#2E7D3200'
  },
  metric_install: {
    color: '#ff5722',
    areaStartColor: '#ff572233',
    areaEndColor: '#ff572200'
  },
  metric_conversion_1: {
    color: '#4caf50',
    areaStartColor: '#4caf5033',
    areaEndColor: '#4caf5000'
  },
  metric_ctr: {
    color: '#673ab7',
    areaStartColor: '#673ab733',
    areaEndColor: '#673ab700'
  },
} as {[key:string]: Pick<CardItem, 'areaEndColor'| 'color' | 'areaStartColor'>}

const decimalArgs = {
  num: 0,
  style: 'decimal',
}
const currencyArgs = {
  num: 0,
  style: 'currency',
  currency: 'USD'
}
const percentArgs = {
  num: 0,
  style: 'percent',
  max: 2
}
export const numberformatArgsObj = {
  metric_cost: currencyArgs,
  metric_impression: decimalArgs,
  metric_click: decimalArgs,
  metric_view_completion: decimalArgs,
  metric_install: decimalArgs,
  metric_conversion_1:decimalArgs,
  metric_conversion_2: decimalArgs,
  metric_conversion_3: decimalArgs,
  metric_ctr: percentArgs,
  metric_cvr: percentArgs,
  metric_cpm: currencyArgs,
  metric_cpc: currencyArgs,
  metric_cpi: currencyArgs,
} as {[key: string]: NumberFormatArgs}