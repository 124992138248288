import { Campaign } from "@/api/interface/index";
import { PORT3 } from "@/api/config/servicePort";
import http from "@/api";

export const getAll = (params: Campaign.ReqGetAll) => {
	return http.post<Campaign.ResGetALL>(PORT3 + `/list`, params);
};

export const campaignUpload = (params: Campaign.ReqCampaignUpload, url: string) => {
	return http.post<Campaign.ResCampaignUpload>(url, params)
}