import { Box, Typography } from "@mui/material";
import { CardItem } from "@/types";
interface CardProps {
  item: CardItem;
  currentData?: CardItem;
  selectChange: (value: CardItem) => void
}
interface NameComProps {
    color: string;
    label: string
}
export const NameCom = (props: NameComProps):JSX.Element => {
    const {color, label} = props
    return <Box
    component={"div"}
    sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: '4px' }}
  >
    <Box
      component={"div"}
      sx={{ width: "10px", height: "3px", bgcolor: color }}
    ></Box>
    <Typography variant="body2" sx={{ lineHeight: 0 }}>
      {label}
    </Typography>
  </Box>
}
function Index(props: CardProps): JSX.Element {
  const { item, selectChange, currentData} = props;
  const { label, total, color } = item;

  return (
    <Box
      component={"div"}
      onClick={() => selectChange(item)}
      sx={{
        justifyContent: "center",
        height: "6.375rem",
        alignItems: "center",
        display: "flex",
        border: "1px solid rgba(0, 0, 0, 0.12)",
        borderRadius: "2.15px",
        flexDirection: 'column',
        gap: 2,
        cursor: 'pointer',
        bgcolor: currentData?.key === item.key ? 'rgba(56, 101, 220, 0.08)': "transparent"
      }}
    >
      <NameCom label={label}  color={color}/>
      <Typography variant="h6">{total}</Typography>

    </Box>
  );
}

export default Index;
