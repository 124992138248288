import { useLocation, Navigate } from "react-router-dom";
import { searchRoute } from "@/utils/util";
import { rootRouter } from "@/routers/index";
import { REPORT_URL } from "@/config";
const AuthRouter = (props: { children: JSX.Element }) => {
	
	const { pathname } = useLocation();
	const route = searchRoute(pathname, rootRouter);
	// * 判断当前路由是否需要访问权限(不需要权限直接放行)
	if (!route.meta?.requiresAuth) return props.children;
	const staticRouter = [REPORT_URL, "/403"];
	const routerList = staticRouter;
	// * 如果访问的地址没有在路由表中重定向到403页面
	if (routerList.indexOf(pathname) === -1) return <Navigate to="/signIn" />;

	// * 当前账号有权限返回 Router，正常访问页面
	return props.children;
};

export default AuthRouter;
