import { LineChart, areaElementClasses } from "@mui/x-charts/LineChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import { chartsGridClasses } from "@mui/x-charts/ChartsGrid";
import { Box } from "@mui/material";
import { CardItem } from "@/types";
import { NameCom } from "./card";
import { numberFormat } from "@/utils/util";
import { numberformatArgsObj } from "@/const";
interface ChartPorps {
  currentData: CardItem;
}
interface ColorPaletteProps {
  id: string;
  areaEndColor: string;
  areaStartColor: string;
}
function ColorPalette(props: ColorPaletteProps) {
  const { id, areaEndColor, areaStartColor } = props;
  return (
    <defs>
      <linearGradient id={id} x1="0%" y1="0%" x2="0%" y2="100%">
        <stop offset={"0%"} stopColor={areaStartColor} stopOpacity={1} />
        <stop offset={"100%"} stopColor={areaEndColor} stopOpacity={1} />
      </linearGradient>
    </defs>
  );
}
function Index(props: ChartPorps) {
  const { currentData } = props;
  const { key, label, color, areaEndColor, areaStartColor, chartData } =
    currentData;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: "24px",
      }}
    >
      <NameCom label={label} color={color} />
      <Box component={"div"} sx={{ width: "100%" }}>
        <LineChart
          xAxis={[
            {
              data: chartData.map((v) => v.date),
              scaleType: "point",
              disableLine: true,
              disableTicks: true,
              tickLabelStyle: { fill: "#C7C7CC" },
            },
          ]}
          yAxis={[
            {
              tickLabelStyle: { fill: "#C7C7CC" },
              valueFormatter: (value): string => {
                const args = numberformatArgsObj[key];
                args.num = Number(value);
                return numberFormat(args);
              },
            },
          ]}
          series={[
            {
              data: chartData.map((v) => v.num),
              showMark: false,
              area: true,
              valueFormatter: (value): string => {
                const args = numberformatArgsObj[key];
                args.num = Number(value);
                return numberFormat(args);
              },
            },
          ]}
          height={328}
          margin={{ top: 20, bottom: 30, left: 75 }}
          colors={[color]}
          grid={{ horizontal: true }}
          sx={{
            width: "90%",
            [`& .${areaElementClasses.root}`]: {
              fill: `url(#${key})`,
            },
            [`& .${chartsGridClasses.line}`]: {
              strokeDasharray: "3 5",
              strokeWidth: 0.5,
              stroke: "rgba(0, 0, 0, 0.12)",
            },
            [`& .${axisClasses.left} .${axisClasses.line}`]: {
              stroke: "#fff",
            },
            [`& .${axisClasses.tickContainer} .${axisClasses.tick}`]: {
              stroke: "#fff",
            },
            [`& .${axisClasses.bottom} .${axisClasses.line}`]: {
              stroke: "#C7C7C7",
            },
          }}
        >
          <ColorPalette
            id={key}
            areaEndColor={areaEndColor}
            areaStartColor={areaStartColor}
          />
        </LineChart>
      </Box>
    </div>
  );
}

export default Index;
