import { useEffect, useRef, useState, RefObject } from "react";
import { Box, Button, Stack, Grid, Typography, Divider } from "@mui/material";
import { metricsList, dimensionList, reportSearchKeyList } from "../../const";
import { AllOptions, FormData } from "../../types";
import DateCard from "../../components/CustomeDate";
import CustomeSelect from "../../components/CustomeSelect";
import Table from "../../components/TableList";
import { RootState, useSelector } from "@/redux";
import { getDistinctFieldValuesApi, queryApi } from "@/api/modules/metric";
import { FilterBy, Metric } from "@/api/interface";
import message from "@/components/Message";
import { SelectOptionItemType } from "../../types";
interface DateRefType {
  validate: (_startTime: string, _endTime: string) => boolean;
}

interface checkedRef {
  validate: (value: SelectOptionItemType[]) => boolean;
}
const Index = (): JSX.Element => {
  const { userInfo, advertiserItem } = useSelector((state: RootState) => ({
    userInfo: state.global.userInfo,
    advertiserItem: state.account.advertiserItem,
  }));
  const [formData, setFormData] = useState({} as FormData);
  const [allOptions, setAllOptions] = useState<AllOptions>({});
  const [tabelData, setTableData] = useState<any[]>([]);
  const dateRef: RefObject<DateRefType> = useRef(null);
  const channelCheckedRef: RefObject<checkedRef> = useRef(null);
  const metricsCheckedRef: RefObject<checkedRef> = useRef(null);
  const onChange = (name: string, data?: any) => {
    setFormData((pre) => ({ ...pre, [name]: data }));
  };
  const allValidate = (): boolean => {
    const dateValidate = dateRef?.current?.validate(
      formData?.date?.[0],
      formData?.date?.[1]
    );
    const channelValidate = channelCheckedRef?.current?.validate(
      formData?.channel
    );
    const metricsValidate = metricsCheckedRef?.current?.validate(
      formData?.metrics
    );
    return Boolean(dateValidate && channelValidate && metricsValidate);
  };
  const onGenerateClick = async () => {
    const validate = allValidate();
    if (!validate) return;
    const visible = [] as string[];
    const groupBy = [] as string[];
    const filterBy = [
      {
        field: "advertiser_key",
        args: [advertiserItem?.key || ""],
      },
    ] as FilterBy[];
    formData.metrics?.forEach((v) => {
      visible.push(v.key);
      if (v.margin && ["admin", "advertiser_admin"].includes(userInfo.role)) {
        visible.push(v.margin);
      }
    });
    formData.dimension?.forEach((v) => {
      groupBy.push(v.key);
    });
    if (formData.date?.length) {
      filterBy.push({
        field: "date",
        args: formData.date,
      });
    }
    if (formData.channel?.length) {
      filterBy.push({
        field: "channel_key",
        args: [formData.channel?.map((v) => v.key)],
      });
    }
    if (formData.campaign?.length) {
      filterBy.push({
        field: "campaign",
        args: [formData.campaign?.map((v) => v.key)],
      });
    }
    if (formData.country?.length) {
      filterBy.push({
        field: "country",
        args: [formData.country?.map((v) => v.key)],
      });
    }
    if (formData.ad_group?.length) {
      filterBy.push({
        field: "ad_group",
        args: [formData.ad_group?.map((v) => v.key)],
      });
    }
    const params = {
      visible,
      group_by: groupBy,
      filter_by: filterBy,
    } as Metric.ReqQuery;
    try {
      const res = await queryApi(params);
      const tableList =
        res.data?.map((v, index) => ({
          ...v,
          id: index,
          date: v?.date?.value,
        })) || [];
      setTableData(tableList);
    } catch (error) {
      message.error("Generate data error");
    }
  };
  const getAllOptions = async () => {
    const all = await Promise.all(
      reportSearchKeyList.map(async (item) => {
        const params = {
          field: item.key,
          filter_by: [
            {
              field: "advertiser_key",
              args: [advertiserItem?.key || ""],
            },
          ],
        };
        const optionsitem = await getDistinctFieldValuesApi(params);
        return {
          name: item.label,
          data:
            optionsitem.data?.filter(v => v)?.map((v) => ({
              label: v?.split("_").join(" "),
              key: v,
            })) || [],
        };
      })
    );
    const obj = {} as AllOptions;
    all.forEach((item) => {
      if (!obj[item.name]) {
        obj[item.name] = item.data;
      }
    });
    setAllOptions(obj);
  };
  useEffect(() => {
    getAllOptions();
  }, []);
  return (
    <Box component="div" sx={{ padding: "0 48px" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography variant="body1">
              Generate report from real time data.
            </Typography>
            <Button
              variant="contained"
              onClick={onGenerateClick}
              sx={{ textTransform: "none" }}
            >
              Generate
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">
            What columns to include in the report?
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <CustomeSelect
            name="channel"
            label="Channel"
            onChange={onChange}
            optionList={allOptions.channel || []}
            required
            ref={channelCheckedRef}
          />
        </Grid>
        <Grid item xs={4}>
          <CustomeSelect
            name="dimension"
            label="Dimension"
            onChange={onChange}
            optionList={dimensionList}
          />
        </Grid>
        <Grid item xs={4}>
          <CustomeSelect
            name="metrics"
            label="Metrics"
            required={true}
            onChange={onChange}
            optionList={metricsList}
            ref={metricsCheckedRef}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">What is the source data?</Typography>
        </Grid>
        <Grid item xs={3}>
          <DateCard
            onChange={onChange}
            name={"date"}
            required={true}
            ref={dateRef}
          />
        </Grid>
        <Grid item xs={3}>
          <CustomeSelect
            name="campaign"
            label="Campaign"
            onChange={onChange}
            optionList={allOptions?.campaign}
          />
        </Grid>
        <Grid item xs={3}>
          <CustomeSelect
            name="country"
            label="Country"
            onChange={onChange}
            optionList={allOptions.country}
          />
        </Grid>
        <Grid item xs={3}>
          <CustomeSelect
            name="ad_group"
            label="Ad Group"
            onChange={onChange}
            optionList={allOptions.ad_group}
          />
        </Grid>
        <Grid item xs={12}>
        <Divider />
      </Grid>
      </Grid>
      <Table
        dimension={formData?.dimension || []}
        metrics={formData?.metrics || []}
        rowData={tabelData}
      />
    </Box>
  );
};

export default Index;
