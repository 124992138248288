import react, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import {
  FormControl,
  FormLabel,
  Stack,
  Checkbox,
  TextField,
  FormHelperText,
  Autocomplete,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { SelectOptionItemType, ErrorInfo } from "../../types";
import styles from "./index.module.scss";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
interface FilterCardProps {
  onChange: (name: string, data?: any) => void;
  optionList: SelectOptionItemType[];
  name: string;
  title?: string;
  label?: string;
  placeholder?: string;
  hiddenLabel?: boolean;
  multiple?: boolean;
  required?: boolean;
  isHasAllSelectItem?: boolean;
  limitTags?: number;
  defaultValue?: SelectDataType;
  textFieldSize?: "medium" | "small";
}
type SelectDataType = SelectOptionItemType | Array<SelectOptionItemType> | null;
const CustomeSelect = (
  props: FilterCardProps,
  ref: react.Ref<unknown> | undefined
): JSX.Element => {
  const {
    onChange,
    name,
    title,
    optionList = [],
    multiple = true,
    required = false,
    hiddenLabel = true,
    label = "",
    placeholder = "",
    isHasAllSelectItem = false,
    defaultValue = multiple ? [] : null,
    limitTags,
    textFieldSize = "medium",
  } = props;
  const [selectData, setSelectData] = useState<SelectDataType>(defaultValue);
  const [errorInfo, setError] = useState<ErrorInfo | null>(null);
  const handleChange = (value: SelectDataType) => {
    let newValue = value;
    if (Array.isArray(value)) {
      const all = value.find((v) => v.key === "all");
      if (all) {
        newValue = optionList;
      }
    }
    validate(newValue);
    setSelectData(newValue);
    onChange(name, newValue);
  };
  const validate = (value: SelectDataType) => {
    if (required) {
      if (value) {
        if (Array.isArray(value) && value.length) {
          setError({ error: false, msg: "" });
          return true;
        }
        if (Object.keys(value)?.length) {
          setError({ error: false, msg: "" });
          return true;
        }
      }
      setError({ error: true, msg: `Please select a ${name}` });
      return false;
    }
    setError({ error: false, msg: "" });
    return true;
  };
  useEffect(() => {
    if (Array.isArray(defaultValue) && defaultValue.length) {
      setSelectData(defaultValue);
      return;
    }
    if (defaultValue) {
      setSelectData(defaultValue);
      return;
    }
  }, [JSON.stringify(defaultValue)]);
  useImperativeHandle(ref, () => ({ validate }));

  return (
    <Stack className={styles.root} spacing={2}>
      <FormControl
        component="fieldset"
        variant="standard"
        error={errorInfo?.error}
      >
        <Autocomplete
          className={styles.auto}
          limitTags={limitTags}
          multiple={multiple}
          value={selectData}
          options={
            isHasAllSelectItem
              ? [...[{ key: "all", label: "All" }], ...optionList]
              : optionList
          }
          disableCloseOnSelect
          onChange={(e, newValue) => handleChange(newValue)}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => option?.key === value?.key}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                indeterminate={
                  option.key === "all" &&
                  Boolean(
                    Array.isArray(selectData) &&
                      selectData.length &&
                      selectData.length !== optionList.length
                  )
                }
                style={{ marginRight: 8 }}
                checked={
                  option.key === "all"
                    ? Boolean(
                        Array.isArray(selectData) &&
                          selectData.length === optionList.length
                      ) || selected
                    : selected
                }
              />
              {option.label}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder={placeholder}
              hiddenLabel={hiddenLabel}
              label={label}
              size={textFieldSize}
              required={required}
            />
          )}
        />
        <FormHelperText error={errorInfo?.error}>
          {errorInfo?.msg || ""}
        </FormHelperText>
      </FormControl>
    </Stack>
  );
};

export default forwardRef(CustomeSelect);
