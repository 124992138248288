import { Button } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import { useSelector, RootState } from '@/redux';
import React, { useMemo } from 'react';
import { uploadApi } from '@/api/modules/metric';
import message from '@/components/Message';
const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

interface UploadBtnProps {
    icon?: React.ReactNode;
    url: string;
    height?: string;
    children?: any;
    styles?: any;
}
const UploadBtn = (props: UploadBtnProps): JSX.Element => {
    const { url, icon, height } = props;
    const { userInfo, advertiserItem } = useSelector((state: RootState) => ({
        userInfo: state.global.userInfo,
        advertiserItem: state.account.advertiserItem,
    }));
    const isShow = useMemo(() => {
        if (['admin'].includes(userInfo?.role)) {
            return true;
        }
    }, [userInfo?.role]);
    const fileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event?.target?.files?.[0];

        if (file && advertiserItem?.key) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('advertiser_key', advertiserItem.key);
            try {
                const res = await uploadApi(formData, url);
                if (res.code === 0) {
                    message.success('upload file success');
                }
            } catch (error) {
                message.error('upload file error');
            }
        }
    };

    return (
        <>
            {isShow ? (
                <Button
                    component='label'
                    role={undefined}
                    variant='contained'
                    tabIndex={-1}
                    startIcon={icon || <CloudUploadIcon />}
                    sx={{
                        textTransform: 'none',
                        mt: 2,
                        height,
                        ...props.styles,
                    }}
                >
                    {props.children}
                    <VisuallyHiddenInput
                        accept='.xlsx'
                        type='file'
                        onChange={fileChange}
                    />
                </Button>
            ) : null}
        </>
    );
};
export default UploadBtn;
