import { Navigate, useRoutes } from 'react-router-dom';
import { RouteObject, RouterMeat } from '@/routers/interface';
import LayoutIndex from '@/components/Layout';
import Home from '@/page/home';
import SignIn from '@/page/signIn';
import Report from '@/page/reports';
import UserConfig from '@/page/userConfig';
import Campaign from '@/page/campaign';
import Summary from '@/page/summary';
export const routerMeat: RouterMeat[] = [
    {
        name: 'Summary',
        icon: '',
        path: '/summary',
        requiresAuth: true,
    },
    {
        name: 'Campaign',
        icon: '',
        path: '/campaign',
        requiresAuth: true,
    },
    {
        name: 'Report',
        icon: '',
        path: '/report',
        requiresAuth: true,
    },
    {
        name: 'user',
        icon: '',
        path: '/user',
        requiresAuth: true,
        isNotShowMenu: true,
    },
];

export const showMenuRouterMeat = routerMeat.filter((v) => !v.isNotShowMenu);
const getRouterList = (routerMeat: RouterMeat[]): RouteObject[] => {
    const elementMap = {
        home: <Home />,
        Report: <Report />,
        user: <UserConfig />,
        Campaign: <Campaign />,
        Summary: <Summary />,
    } as {
        [key: string]: React.ReactNode;
    };
    return routerMeat?.map((item) => ({
        element: <LayoutIndex />,
        children: [
            {
                path: item.path,
                element: elementMap[item.name],
                meta: {
                    requiresAuth: false,
                    title: item.name,
                    key: item.name,
                },
            },
        ],
    }));
};
export const rootRouter: RouteObject[] = [
    {
        path: '/',
        element: <Navigate to='/summary' />,
    },
    {
        path: '/signIn',
        element: <SignIn />,
        meta: {
            requiresAuth: false,
            title: '登录页',
            key: 'signIn',
        },
    },
    ...getRouterList(routerMeat),
    {
        path: '*',
        element: <Navigate to='/404' />,
    },
];

const Router = () => {
    const routes = useRoutes(rootRouter);
    return routes;
};

export default Router;
